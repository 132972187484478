<template>
  <div class="d-flex w-100 overflow">
    <div class="overflow-scroll p-1">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
           <span><feather-icon icon="MenuIcon"/></span>
        </b-button>
        <b-button 
          variant="outline-secondary" 
          class="size-button-center m-5rem icon-setting-sider d-xl-none"
          @click="sider_option_ai_responsive = true"
        >
          <b-icon icon="gear-wide-connected" font-scale="1.3" variant="dark"></b-icon>
        </b-button>
      </div>
      <div class="h-100 flex-grow"  ref="main-container-chat">
        <div class="default-conversation" v-if="Object.keys(conversation_selected).length === 0">
          <div class="sender mb-2" :class="{'receiver': i === 2}" v-for="i in 3" :key="i" :style="`opacity: ${1 - 0.23 * i}`">
            <div class="avatar"></div>
            <div class="w-100 ml-2" :class="{'d-flex flex-column align-items-end mr-2': i === 2}">
              <div class="title-conversation"></div>
              <div class="message-bubble"></div>
            </div>
          </div>
        </div>

        <div class="conversation-chat" v-if="Object.keys(conversation_selected).length > 0">
          <div class="sender mb-2" :class="{'receiver': msg.role === 'user'}" v-for="(msg, index) in conversation_selected.messages" :key="index">
            <b-avatar class="avatar" :src="msg.role === 'user' ? image_profile : require('@/assets/images/icons/chat-bot.svg')"></b-avatar>
            <div class="w-100 ml-2" :class="{'d-flex flex-column align-items-end mr-2': msg.role === 'user'}">
              <div class="title-conversation w-100">
                <div v-if="msg.role === 'assistant'" class="d-flex justify-content-between align-items-start">
                  <span class="avenir-medium cursor-pointer d-block" @click="copyAction(msg.content)" v-if="!msg.type">{{$t('ia.chat.copy')}}</span>
                  <span v-else class="d-block"></span>
                  <b-dropdown
                    no-caret
                    right
                    variant="link"
                    class="avenir-medium"
                    id="dropdown-options-chat"
                  > 
                    <template #button-content>{{$t('ia.chat.actions')}}
                      <feather-icon icon="ChevronDownIcon"></feather-icon>
                    </template>
                    <div v-if="!msg.type">
                      <b-dropdown-item @click="sidebar_export_chat = true; message_selected = msg.content">{{ $t('tracking.export') }}</b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>
              <div class="message-bubble">
                <div v-if="!msg.type"> {{ msg.content }}</div>
                <div v-else class="row"> 
                  <div
                    v-for="(data, index) in msg.content"
                    :class="'container-aspect-ratio col-12 col-sm-6 col-md-3 mb-1'"
                    :key="index"
                    :ref="`aspect_ratio_box_${index}`"
                  >           
                    <div class="h-100 aspect-ratio">
                      <div v-if="true" class="w-100 h-100 position-relative">
                        <div class="container-dropdown-image p-03" @click="downloadFile(data)">
                          <feather-icon icon="DownloadIcon" class="text-white"></feather-icon>
                        </div>
                        <img :src="data" class="img-ia"/>
                        <div class="container-ia-svg">
                          <img :src="require('@/assets/images/icons/ia.svg')" :alt="`ia-svgd`" class="ia-svg-icon">
                        </div>
                      </div>
                      <b-skeleton class="skeleton-loading-ia" v-else></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="background-input-botton" v-if="!is_history">
        <small class="small-text">{{$t('ia.chat.textError')}}</small>
        <div class="d-flex justify-content-between align-items-center div-input-button">
          <b-form-input 
            autocomplete="off" 
            :placeholder="placeholder_text" 
            class="size-input" 
            v-model="options_text.prompt" 
            @keyup.enter="(is_survery_section) ? startConversation() : generateTextAction()" 
            ref="textInput"
            @focus="onFocusInputSearch"
            @blur="onBlurInputSearch"
          />
          <b-button
            class="generate-img-ai"
            v-if="!loader_chat && !is_survery_section"
            @click="generateTextAction"
            :disabled="!options_text.prompt"
          >
            <feather-icon icon="SendIcon" class="send-icon"></feather-icon>
            <b-badge class="badge-button-ia">
              <feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon>
              {{ getCredits() >= 0 ? getCredits() : $t('ia.general.insufficientCredits') }}
            </b-badge>
          </b-button>
          <b-button
            class="generate-img-ai"
            v-else-if="!loader_chat && is_survery_section"
            @click="startConversation()"
            :disabled="!options_text.prompt"
          >
            {{$t('next')}}
            <b-badge class="badge-button-ia">
              <feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon>
              {{ getCredits() >= 0 ? getCredits() : $t('ia.general.insufficientCredits') }}
            </b-badge>
          </b-button>
          <b-skeleton v-else type="button" class="generate-img-ai"></b-skeleton>
        </div>
      </div>
      
      
      <div v-else class="d-flex justify-content-between border-top pt-2">
        <div>
          <b-button variant="outline-secondary" @click="backToHistory()">{{ $t('ia.general.back') }}</b-button>
        </div>
        <div>
          <b-button variant="outline-secondary" @click="$emit('change_uuid', 'backward', 'social_texts')">
            <feather-icon icon="ChevronLeftIcon"></feather-icon>
          </b-button>
          <b-button variant="outline-secondary" class="ml-1" @click="$emit('change_uuid', 'forward', 'social_texts')">
            <feather-icon icon="ChevronRightIcon"></feather-icon>
          </b-button>
        </div>
      </div>
    </div>

    <div class="width-right-area d-none d-xl-block">
      <sider-right-text-options
        :options="options_text"
        :prompt_builder="prompt_builder"
        :conversation_selected="Object.keys(conversation_selected).length > 0 && is_history ? conversation_selected : null"
        @back_history="editHistory"
        @update_prompt="updatePrompt"
        ref="sider_right_options"
      ></sider-right-text-options>
    </div>
    <b-sidebar v-model="sider_option_ai_responsive" backdrop shadow right class="sidebar-right-responsive-options-models-ia">
      <sider-right-text-options
        :options="options_text"
        :prompt_builder="prompt_builder"
        :conversation_selected="Object.keys(conversation_selected).length > 0 && is_history ? conversation_selected : null"
        @back_history="editHistory"
        @update_prompt="updatePrompt"
        ref="sider_right_options"
      ></sider-right-text-options>
    </b-sidebar>

    <b-sidebar v-model="sidebar_export_chat" backdrop shadow right no-header>
      <div class="d-flex justify-content-between border-bottom p-1 mb-1">
        <h4>{{ $t('tracking.export') }}</h4>
        <feather-icon icon="XIcon" class="close-icon-sidebar-export" size="25" @click="sidebar_export_chat = false"/>
      </div>

      <div class="d-flex flex-column p-1">
        <strong>{{ $t('mediaKits.download') }}</strong>
        <p class="avenir-medium">{{ $t('ia.chat.saveFile') }}</p>
        <b-button variant="outline-secondary mb-1 avenir-medium text-left" @click="exportMessageAction(message_selected, 'txt')"><b-icon icon="file-earmark-text-fill"></b-icon> {{ $t('ia.chat.textFile') }}</b-button>
        <b-button variant="outline-secondary avenir-medium text-left" @click="exportMessageAction(message_selected, 'doc')"><b-icon icon="file-earmark-word-fill"></b-icon> {{ $t('ia.chat.wordDocument') }}</b-button>
      </div>
    </b-sidebar>
  </div>
</template>
    
<script>

import {
  BFormInput,
  BBadge,
  BButton,
  BSkeleton,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BSidebar,
} from 'bootstrap-vue';
import { 
  generateText, 
  getPriceActions, getSocialText, exportMessageTextIa } from '@/services/ia';
import { getUserData } from '@/libs/utils/user';
import { downloadFile } from '@/libs/utils/others';
import { nextTick } from '@vue/composition-api';
import { verifyAsset, } from '@/libs/utils/urls';

const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'textContainer',
  components: {
    BFormInput,
    BButton,
    BBadge,
    BSkeleton,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BSidebar,
    siderRightTextOptions: () => import('@/views/components/brandme-ai/siderbars/siderRightTextOptions.vue')
  },
  data() {
    return {
      verifyAsset,
      downloadFile,
      options_text: {
        prompt: '',
        network: 'Instagram',
        quantity: 1,
        point_of_view: this.$t('ia.text.persons')[0],
        language: this.$t('instaIdioma').find(i => i.value === 'es'),
      },
      image_profile: this.getImgProfile(getUserData().profile_image),
      loader_chat: false,
      conversation_selected: {},
      placeholder_text: '',
      sidebar_chat_responsive: false,
      is_focus: false,
      message_selected: null,
      sidebar_export_chat: false,
      credit_price: 0,
      
      prompt_builder: {
        writing_approach: {
          tone: "",
          style: "",
          genre: "",
          rhetoric: "",
          emoji: '',
          hashtags: ''
        },
        writer_persona: {
          persona_age: "",
          persona_gender: "",
          persona_intellect: "",
          persona_mindset: "",
          persona_occupattion: ""   
        },
        goals: {
          first_goal: "",
          second_goal: ""
        },
      },
      sider_option_ai_responsive: false,
      index_current_question: 0,
      is_animating_placeholder: false,
      animation_placeholder_timeout: null,
    }
  },
  props: {
    credits_avaible: {
      type: Number,
      default: 0
    },
    text_selected: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    is_history() {
      return this.$route.params.section && this.$route.params.section === 'histories' 
    },
    get_text_type() {
      return this.$route.params.category.replace('-', '_')
    },
    is_survery_section() {
      return this.get_text_type === 'product_review' || this.get_text_type === 'brand_pitch' || this.get_text_type === 'email_campaign'
    }
  },
  async mounted() {
    if (!this.is_history) this.startAnimation();
  },
  created() {
    this.mainCreatedLogic();
    
    this.$root.$on('social_text_response', (data) => {
      this.loader_chat = false;
      this.conversation_selected = data.body;
      if (this.is_survery_section) this.setMessagesFromMetadata();
      nextTick(() => { this.scrollToBottomInChatLog() })

      if (data.body.status === 'complete') {
        this.$emit('update_credits', this.getCredits())
        this.$root.$emit('update_credits_drop', this.getCredits())
      }
    })    
  },
  methods: {
    mainCreatedLogic() {
      this.getPricesCredit()
      if (Object.keys(this.text_selected).length > 0) {
        this.conversation_selected = this.text_selected;
        const text_type = this.conversation_selected.type_text;
        if (text_type === 'product_review' || text_type === 'brand_pitch' || text_type === 'email_campaign') this.setMessagesFromMetadata()
        this.formatPrompt(this.conversation_selected.data)
      } else if (this.$route.params.uuid) {
        this.getSocialTextByUuid()
      }
    },
    startConversation() {
      if (this.index_current_question === 0) this.conversation_selected.messages = [];

      this.conversation_selected.messages.push({role: 'assistant', content: this.$t('ia.text.survey')[this.get_text_type][this.index_current_question].question})
      this.conversation_selected.messages.push({role: 'user', content: this.options_text.prompt})
      this.$t('ia.text.survey')[this.get_text_type][this.index_current_question].answer = this.options_text.prompt;
      this.options_text.prompt = '';
      if (this.index_current_question < this.$t('ia.text.survey')[this.get_text_type].length - 1) this.index_current_question += 1;
      else {
        this.index_current_question = 0;
        this.generateTextAction();
      }
      
      this.$refs['textInput'].blur()
      nextTick(() => { this.scrollToBottomInChatLog() })
    },
    scrollToBottomInChatLog() {
      const main_container = this.$refs['main-container-chat']
      main_container.scrollTop = main_container.scrollHeight;
    },
    editHistory(value) {
      this.$emit('back_history', value, this.$route.params.category)
    },
    setMessagesFromMetadata() {
      let index = 0
      const start_conversation = []
      for (const key in this.conversation_selected.data.metadata) {       
        const message_assistant = {content: this.$t('ia.text.survey')[this.conversation_selected.type_text][index].question, role: 'assistant'}
        const message_user = {content: this.conversation_selected.data.metadata[key], role: 'user'}
        start_conversation.push(message_assistant, message_user)
        index += 1;
      }
      this.conversation_selected.messages = [...start_conversation, ...this.conversation_selected.messages]
    },
    getSocialTextByUuid() {
      const uuid = this.$route.params.uuid;
      getSocialText(uuid).then((response) => {
        if (response.uuid) {
          const language = this.$t('instaIdioma').find(i => i.value === response.data.language)
          this.conversation_selected = response;
          if (this.is_survery_section) this.setMessagesFromMetadata();
          this.formatPrompt(response.data)
          this.options_text.prompt = response.prompt;
          this.options_text.network = response.data.network;
          this.options_text.quantity = response.quantity;
          this.options_text.point_of_view = response.data.point_of_view;
          this.options_text.language = language ? language : {value: 'es', text: 'Spanish'} 
        }
      })
    },
    formatPrompt(data) {
      for (const [item, value] of Object.entries(data)) {
        for (const section in this.prompt_builder) {
          if (Object.prototype.hasOwnProperty.call(this.prompt_builder[section], item)) {
            this.prompt_builder[section][item] = value;
            break;
          }
        }
      }
    },
    backToHistory() {
      this.$router.push({ name: 'brandme_AI', params: { section: 'histories', category: 'text'}}).catch();
      this.$emit('back_histories');
    },
    getPricesCredit() {
      getPriceActions().then((response) => {
        this.credit_price = response.social_text;
      })
    },
    getMetadataQuestions() {
      const obj = {};
      this.$t('ia.text.survey')[this.get_text_type].forEach((question, index) => {
        obj[`question_${index}`] = question.answer;
      })
      return obj;
    },
    generateTextAction() {
      this.loader_chat = true;
      
      if (this.getCredits() >= 0) {
        const data = {
          type_text: this.get_text_type,
          prompt: this.is_survery_section ? this.getFormatedPrompt() : this.options_text.prompt,
          network: this.options_text.network,
          quantity: this.options_text.quantity,
          point_of_view: this.options_text.point_of_view,
          language: this.options_text.language.value,
          tone: this.prompt_builder.writing_approach.tone,
          style: this.prompt_builder.writing_approach.style,
          genre: this.prompt_builder.writing_approach.genre,
          rhetoric: this.prompt_builder.writing_approach.rhetoric,
          emoji: this.prompt_builder.writing_approach.emoji,
          hashtags: this.prompt_builder.writing_approach.hashtags,
          persona_age: this.prompt_builder.writer_persona.persona_age,
          persona_gender: this.prompt_builder.writer_persona.persona_gender,
          persona_intellect: this.prompt_builder.writer_persona.persona_intellect,
          persona_mindset: this.prompt_builder.writer_persona.persona_mindset,
          persona_occupattion: this.prompt_builder.writer_persona.persona_occupattion,
          first_goal: this.prompt_builder.goals.first_goal,
          second_goal: this.prompt_builder.goals.second_goal,
          metadata: this.is_survery_section ? this.getMetadataQuestions() : null
        }

        const formated_data = {}
        for (const i in data) {
          if (data[i]) formated_data[i] = data[i];
        }

        generateText(formated_data).then((response) => {
          if (this.is_survery_section) {
            const previews_msj = this.conversation_selected.messages;
            this.conversation_selected = response;
            this.conversation_selected.messages = [...previews_msj, ...this.conversation_selected.messages]
            nextTick(() => { this.scrollToBottomInChatLog() })
          } else {
            this.conversation_selected = response;
          }
          this.$router.push({ name: 'brandme_AI', params: { section: 'text' , category: this.$route.params.category, uuid: response.uuid}}).catch(() => {})
        });
      }
    },
    exportMessageAction(message, type_export) {
      const data = {
        type_export,
        message,
      }
      exportMessageTextIa(data).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `message.${type_export === 'doc' ? 'docx' : 'txt'}`); // o el nombre de archivo que prefieras
        document.body.appendChild(link);
        link.click();
      })
    },
    downloadAllFiles(files) {
      files.forEach(file => {
        downloadFile(file);
      });
    },
    onFocusInputSearch() {
      this.is_focus = true;
      this.placeholder_text = '';
    },
    onBlurInputSearch() {
      this.is_focus = false;
      this.startAnimation();
    },
    getCredits() {
      return this.credits_avaible >= (this.credit_price * this.options_text.quantity) ? (this.credit_price * this.options_text.quantity) : -1;
    },
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return verifyAsset(image_url);
      const img = require(`@/assets/images/avatars/${
        Math.floor(Math.random() * 4) + 1
      }.png`);
      return img;
    },
    getFormatedPrompt() {
      let prompt = ''
      this.$t('ia.text.survey')[this.get_text_type].forEach(i => {
        prompt += `${i.formated_prompt} ${i.answer}, `
      })
      return prompt;
    },
    startAnimation() {
      if (this.is_animating_placeholder) {
        clearTimeout(this.animation_placeholder_timeout);
        this.is_animating_placeholder = false;
      }
      this.is_animating_placeholder = true;
      const placeholder_text = this.is_survery_section ? this.$t('ia.text.survey')[this.get_text_type][this.index_current_question].question : this.$t('ia.text.placeholders')[this.get_text_type];
      const speed = 50;
      this.animatePlaceholder(placeholder_text, speed);
    },
    async typeWriter(text, index, speed) {
      if (index < text.length && !this.is_focus) {
        this.placeholder_text += text.charAt(index);
        this.animation_placeholder_timeout = setTimeout(async () => {
          await this.typeWriter(text, index + 1, speed);
        }, speed);
      } else {
        this.is_animating_placeholder = false;
      }
    },
    async animatePlaceholder(text, speed) {
      this.placeholder_text = '';
      await this.typeWriter(text, 0, speed);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    copyAction(msg) {
      this.$copyText(msg).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            props: {
              title: this.$t('ia.chat.msjCopy'),
              icon: "CopyIcon",
              text: '',
              variant: 'success'
            },
          });
        })
      });
    },
    updatePrompt(prompt) {
      if (Object.keys(prompt).length > 0) {
        this.prompt_builder = prompt
      } else {
        this.prompt_builder = {
          writing_approach: {
            tone: "",
            style: "",
            genre: "",
            rhetoric: "",
            emoji: '',
            hashtags: ''
          },
          writer_persona: {
            persona_age: "",
            persona_gender: "",
            persona_intellect: "",
            persona_mindset: "",
            persona_occupattion: ""   
          },
          goals: {
            first_goal: "",
            second_goal: ""
          },
        }
      }
    }
  },
  watch: {
    '$route.params.category'() {
      this.index_current_question = 0
      this.conversation_selected = {}
      this.options_text.prompt = ''
      this.mainCreatedLogic();
      this.startAnimation()
    }
  }
}
</script>
<style lang="scss" scoped>
.width-right-area {
  width: 420px;
  flex-shrink: 0;
}
.p-03 {
  padding: 0.3em;
}
.container-dropdown-image {
  background-color: rgba(0, 0, 0, 0.699);
  position: absolute;
  top: 5%;
  border-radius: 0.5em;
  right: 5%;
  transform: translateX(70px);
}
.aspect-ratio {
  background: linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(219,219,219,1) 100%);
  display: flex;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  
  .skeleton-loading-ia {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .img-ia {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container-ia-svg {
    position: absolute;
    width: 5%;
    height: 5%;
    right: 5%;
    background-color: white;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ia-svg-icon {
      width: 170%;
      height: 170%;
    }
  }
}
.size-input::placeholder {
  color: #70747c;
}
.overflow {
  height: 100%;
}

.default-conversation {
  width: 100%;
  .sender  {
    display: flex;
    width: 100%;
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background-color: #E9ECEF;
      flex-shrink: 0;
    }
    .title-conversation {
      width: 110px;
      height: 20px;
      margin-bottom: 0.4em;
      background-color: #E9ECEF;
    }
    .message-bubble {
      width: 95%;
      height: 115px;
      background-color: #e9ecef;
      border-radius: .5em;
      position: relative;

      &::before {
        border: 10px solid#e9ecef;
        content: '';
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent ;
        position: absolute;
        left: -10px;
      }
    }
  }
  .receiver {
    flex-direction: row-reverse;
    .message-bubble  {
      &::after {
        border: 10px solid #e9ecef !important;
        content: '' !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent;
        position: absolute !important;
        right: -10px !important;
      }
      &::before {
        border: 10px solid transparent !important;
        content: '' !important;
      }
    }
  }
}
.flex-grow {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.conversation-chat {

  width: 100%;
  height: 100% !important;
  .sender  {
    display: flex;
    width: 100%;
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background-color: #E9ECEF;
      flex-shrink: 0;
    }
    .title-conversation {
      width: 95% !important;
      height: 20px;
      margin-bottom: 0.4em;
    }
    .message-bubble {
      width: 95%;
      height: auto;
      background-color: #e9ecef;
      border-radius: .5em;
      position: relative;
      color: black;
      font-family: 'avenir-medium';
      padding: 1.6em;
      white-space: pre-line;

      &::before {
        border: 10px solid#e9ecef;
        content: '';
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent ;
        position: absolute;
        top: 0;
        left: -10px;
      }
    }
  }
  .receiver {
    flex-direction: row-reverse;
    // margin-top: -em;
    .message-bubble  {
      background-color: #55a6c4;
      color: white;
      &::after {
        border: 10px solid #55a6c4 !important;
        content: '' !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent;
        position: absolute !important;
        right: -10px !important;
        top: 0;
      }
      &::before {
        border: 10px solid transparent !important;
        content: '' !important;
      }
    }
  }
}

.overflow-scroll {
  // overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  .mr-05 {
    margin-right: 0.5em;
  }
  .size-button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 3rem;
  }
  .background-input-botton {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .small-text {
      background-color: #eeeeee;
      padding: 0.2em 0.5em;
      font-family: 'avenir-light';
      border-radius: 1em;
      margin: 0.4em 0;
    }
    .div-input-button {
      width: 100%;
      backdrop-filter: blur(2px);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5em;
      .send-icon {
        height: 20px;
        width: 20px;
        margin-right: 0.5em;
      }
      .badge-button-ia {
        display: flex;
        align-items: center;
        margin-left: 0.5em;
        line-height: 10px;
        background-color: #40736E;
      .icon-ia {
          width: 14px;
          height: 14px;
          margin-right: 0.2em;
        }
      }
      .generate-img-ai{
        height: 42px;
        display: flex;
        align-items: center;
        background: linear-gradient(to left bottom, rgb(124, 214, 175), rgb(62, 123, 160));
      }
      .size-input{
        background-color: transparent;
        height: 100%;
        width: 80%;
        font-size: 15px;
        font-weight: bold;
        border: none;
        box-shadow: none !important;
        font-family: monospace !important;

        @media(max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
}
.card-chat-history {
  width: 290px;
  flex-shrink: 0;
  margin: 0;
  overflow-y: scroll;
  // padding-bottom: 100px;
  .chat-list {
    // background-color: #dee2e6;
    padding: 1em;
    border-radius: 0.5em;
    font-family: 'avenir-medium';
    display: flex;
    position: relative;

    .actions-chat-list {
      box-shadow: 10px 10px 10px #fff;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.5em;
    }

    &:hover {
      background-color: #dee2e6af;
      cursor: pointer;
    }
    .icon-chat-list {
      width: 20px;
      height: 20px;
      display: block;

      &.disabled {
        color: #bcbaba;
      }
    }
    .text-title-span {
      margin-left: 0.5em;
      width: auto;
      max-width: 150px;
      display: block;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
  .active-chat-list {
    background-color: #dee2e6 !important;
  }
}
</style>
<style lang="scss">
.sidebar-right-responsive-options-models-ia .b-sidebar {
  width: 400px;
}
#dropdown-options-chat {
  .btn {
    padding: 0 !important;
    color: black;
  }
  .icon-ia-actions {
    margin-left: 1.5em;
    color: #5582f6;
    .icon-ia {
      width: 17px;
      height: 17px;
    }
  }
}
</style>
